import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from './routes'

Vue.use(VueRouter)

const Router = new VueRouter({
  /*
   * NOTE! Change Vue Router mode from quasar.conf.js -> build -> vueRouterMode
   *
   * If you decide to go with "history" mode, please also set "build.publicPath"
   * to something other than an empty string.
   * Example: '/' instead of ''
   */

  // Leave as is and change from quasar.conf.js instead!
  mode: process.env.VUE_ROUTER_MODE,
  base: process.env.VUE_ROUTER_BASE,
  scrollBehavior: () => ({ y: 0 }),
  routes
})

import AuthService from '../services/AuthService'

Router.beforeEach((to, from, next) => {
  const { authenticated } = new AuthService()
  if (to.fullPath.indexOf('/access_token') === 0) {
    let callback = `/callback#${to.fullPath.slice(1)}`
    console.log('[Router.beforeEach] [next] %s', callback)
    next({ path: callback })
  }
  if (to.matched.some(record => record.meta.authRequired)) {
    if (!authenticated) {
      next({ path: '/' })
    }
  }
  if (to.matched.some(record => record.meta.authRedirect)) {
    if (authenticated) {
      next({ path: to.meta.authRedirect })
    }
  }
  next()
})

export default Router
