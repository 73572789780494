import axios from 'axios'
// import store from '../store'

var axiosBaseURL = (window.location.host.split('.').indexOf('app') >= 1)
  ? 'https://api.mywings.app'
  : [window.location.protocol, '//', window.location.hostname, ':8888'].join('')

const axiosLIO = axios.create({
  baseURL: axiosBaseURL,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true
})

export default ({ Vue }) => {
  Vue.prototype.$axios = axios

  // intercept axiosLIO requests
  axiosLIO.interceptors.request.use((r) => {
    if (!r.params) r.params = {}
    let ioSessionToken = localStorage.getItem('io_session_token')
    if (ioSessionToken) {
      r.params.io_session_token = ioSessionToken
    }
    let ioAccessToken = localStorage.getItem('io_access_token')
    if (ioAccessToken) {
      r.params.io_access_token = ioAccessToken
    }
    // this.$emit('startAjaxBar')
    return r
  })
  // intercept axiosLIO responses
  axiosLIO.interceptors.response.use((r) => {
    // this.$emit('stopAjaxBar')
    return r
  })
}

export { axios, axiosLIO }
