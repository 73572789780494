export default {
  lang: 'zh-CN',
  // App: Common
  YES: '是',
  NO: '没有',
  CANCEL: '取消',
  CLOSE: '关闭',
  DONE: '完成',
  OK: '确定',
  EDIT: '编辑',
  VIEW: '视图',
  USE: '采用',
  MORE: '更多',
  ADD: '加',
  REMOVE: '去掉',
  LEARN_MORE: '学到更多',
  GET_STARTED: '启动或登录以继续',
  LOGGED_IN: '登录',
  NOT_LOGGED_IN: '未登录',
  NOT_FOUND: '未找到',
  LANGUAGE: '语言',
  LOGOUT: '登出',
  LOGOUT_MESSAGE: '你确定你要退出吗？',
  HELLO_FRIEND: '你好，朋友！',
  WELCOME_FRIEND: '欢迎您，朋友！',
  HELLO_NAME: '{name}，朋友！',
  WELCOME: '欢迎',
  PROGRESS: '进展',
  COMPLETE: '完成',
  PROFILE: '轮廓',
  SHARE: '分享',
  VERIFY: '校验',
  VERIFIED_REQUESTED: '要求验证......',
  VERIFIED_SENT: '验证已发送...',
  VERIFIED_EXPIRED: '验证已过期',
  VERIFIED_VERIFIED: '验证',
  VERIFIED: '验证',
  VERIFIED_NOT: '尚未声明',
  LEGAL_RIGHTS: '版权所有.',
  MORE_DETAIL: '更多详情',
  FIND: '找',
  ABBR_EXAMPLE: '例如',
  OVERVIEW: '概观',
  SUMMARY: '摘要',
  FOUND: '{amount} 发现',
  NAVIGATE_BACK: '回去',
  ANONYMOUS_FRIEND: '匿名朋友',
  APPEARANCE: '出现',
  APPEARANCE_DARKMODE: '暗模式',
  APPEARANCE_DARKMODE_INFO: '始终启用黑暗模式',
  CHANNEL: {
    LABEL_TT: '渠道'
  },
  ORDERBY: {
    LABEL_TT: '排序依据',
    ADD: '添加服务'
  },
  OPEN: '打开',
  CLOSED: '关闭',
  CONNECTING: '正在连线...',
  ONLINE: {
    LABEL: '线上'
  },
  OFFLINE: {
    LABEL: '离线'
  },
  CALL: '呼叫',
  AVAILABILITY: {
    LABEL: '可用性',
    PARTIAL: '提供部分服务',
    FULL: '全套服务',
    CALLS_YES: '给我们打电话!',
    CALLS_NO: '不要打电话'
  },
  LOYALTY: {
    LABEL: '忠诚',
    LABEL_TT: '忠诚'
  },
  SERVICES: {
    LABEL_TT: '服务',
    ADD: '添加服务'
  },
  WIFI: {
    LABEL: 'Wi-Fi'
  },
  WAIT_TIME: {
    LABEL: '等待时间',
    D: '传达送达新订单所需的平均时间。'
  },
  SERVICE_TIME: {
    LABEL: '服务时间',
    D: '传达送达订单所需的平均时间。'
  },
  OPERATIONS: {
    LABEL_TT: '运作方式'
  },
  OFFERS: {
    LABEL: '优惠',
    LABEL_TT: '当前报价',
    ADD: '添加报价'
  },
  TASKS: {
    LABEL_TT: '任务',
    LABEL_ALT_TT: '去做',
    ADD: '添加任务'
  },
  REQUESTS: {
    LABEL_TT: '要求'
  },
  MEDIA: {
    PHOTOS: {
      ADD: '添加照片',
      DROP: '放下照片',
      PROCESSING: '处理照片'
    }
  },
  CHANGES: {
    LABEL_TT: '查看更改'
  },
  PROFILES: {
    SWITCH: {
      LABEL_TT: '改变兴趣',
      MESSAGE: '您想将您的兴趣转换为{profile}吗？'
    }
  },
  CLAIM: {
    BUSINESS: '索赔业务'
  },

  ORDERS: {
    LABEL: '订单',
    ACCEPTING: '接受订单',
    NOT_ACCEPTING: '不接受订单'
  },
  MINUTE: '分钟',
  MINUTE_MIN: '分钟',
  MINUTES_VAL: '-- | 1分钟 | {count}分钟',
  MINUTES_ATLEAST_VAL: '-- | 至少1分钟 | 至少{count}分钟',

  LIVE: '现场直播',
  COMMUNICATION: {
    LIVE: '现场交流',
    LAST: '上次已知通讯'
  },

  FOODS: {
    LABEL_TT: '食物'
  },
  DINE_IN: {
    LABEL: '在用餐'
  },
  AUTO: {
    LABEL_TT: '汽车'
  },
  BAYS: {
    LABEL: '车湾',
    COUNT: '0 车湾 | 1 车湾 | {count} 车湾'
  },
  ALL: '所有',
  NONE: '没有',
  BUSINESS: {
    LABEL_TT: '商业'
  },
  PICKUP: {
    LABEL_TT: '拾起'
  },
  PARKING: {
    LABEL: '停车处'
  },
  DRIVETHRU: {
    LABEL: '直通车'
  },
  WALKINS: {
    LABEL: '步入式'
  },
  CURBSIDE: {
    LABEL: '路边'
  },
  DRIVETHRU_ORDERING: {
    LABEL: '直通车'
  },
  ACCEPTS: {
    LABEL_TT: '接受'
  },
  CASH: {
    LABEL: '现金'
  },
  CREDITCARD: {
    LABEL: '信用卡'
  },
  CRPTO: {
    LABEL: '加密货币'
  },
  APPLEPAY: {
    LABEL: 'Apple Pay'
  },
  UBEREATS: {
    LABEL: 'Uber Eats'
  },
  DOORDASH: {
    LABEL: 'DoorDash'
  },
  POSTMATES: {
    LABEL: 'Postmates'
  },
  GRUBHUB: {
    LABEL: 'Grubhub'
  },
  HOMES: {
    LABEL_TT: '家园'
  },
  AMENITIES: {
    LABEL: '便利设施'
  },
  AVAILABLE_PROPERTIES: {
    LABEL: '可用性'
  },
  DEPARTMENT_STORE: {
    LABEL_TT: '部'
  },
  INVENTORY: {
    LABEL: '库存',
    FULL: '充分',
    PARTIAL: '部分的',
    LOW: '低'
  },
  ANIMALS: {
    LABEL_TT: '动物'
  },
  TELEHEALTH: {
    LABEL: '远程医疗'
  },
  WEBSITE: {
    LABEL: '网站',
    OFFICIAL: '官方网站'
  },
  MENU: {
    LABEL: '菜单'
  },
  PHONE: {
    LABEL: '电话'
  },
  IDENTITY: {
    LABEL: '要求'
  },

  // App: Version
  VERSION: {
    L: '版',
    LATEST: '你是最新的！',
    VIEW_RELEASE_NOTES: '查看发行说明',
    CHECK_UPDATES: '检查更新'
  },

  // App: Support
  SUPPORT: {
    L: '支持',
    D: '我们在这里提供帮助！',
    SYSTEM_STATUS_OK: '系统启动并运行。',
    VISIT_FAQ: '访问常见问题解答',
    REPORT_ISSUE: '报告问题'
  },

  // App: Credits
  CREDITS: {
    L: '学分',
    T: '谢谢！',
    D: '只有你的爱和支持才能继续帮助我们成长和转变。',
    L_SUPPORTERS: '支持者',
    L_ADVISORS: '顾问',
    L_PARTNERS: '伙伴',
    L_TECHNOLOGIES: '技术'
  },

  // App: Errors
  ERRORS: {
    E404: {
      L: '找不到您要查找的页面。',
      D: '没找到。'
    }
  },

  // App: Session
  SESSION: {
    ENDED: {
      L: '会议结束。',
      D: '登录以开始新会话。'
    }
  },

  // App: Pull-to-Refresh
  PTR: {
    RELEASE: '释放刷新',
    REFRESH: '正在刷新...',
    PULL: '下拉刷新'
  },

  // App: Drawer
  DRAWER: {
    HEADER: {
      ACCOUNT: '帐户',
      APP: '应用',
      SOCIAL: '社会',
      LEGAL: '法律'
    },
    ITEM: {
      PROFILES: {
        L: '生活方式',
        D: '管理你的兴趣'
      },
      PREFERENCES: {
        L: '喜好',
        D: '语言，外观等......'
      },
      GET_STARTED: {
        L: '启动或登录以继续',
        D: '启动或登录以继续'
      },
      WALLET: {
        L: '钱包',
        D: '支付方式'
      },
      LOGOUT: {
        L: '登出',
        D: '保存并退出应用程序'
      },
      ABOUT: {
        L: '关于',
        D: '版本和支持'
      },
      UPDATES: {
        L: '发行说明',
        D: '发布更改和更新'
      },
      RELEASE: {
        L: '检查更新...',
        D: '将应用更新到最新版本'
      },
      HELP: {
        L: '救命',
        D: '指南和常见问题解答'
      },
      FEEDBACK: {
        L: '反馈',
        D: '我们很乐意听取您的意见'
      },
      PRIVACY: {
        L: '隐私政策',
        D: '我们非常重视隐私'
      }
    }
  },

  // App: Updates
  UPDATES: {
    TITLE_CHECK: '检查更新...',
    TITLE_NEW: '新的更新',
    TITLE_NO_UPDATES: '没有更新',
    MESSAGE_NEW: '可以下载新的更新。',
    UPDATE: '更新',
    NOT_NOW: '现在不要'
  },

  // App: Authentication
  AUTH: {
    AUTHENTICATION: '验证...',
    LOGOUT: '退出...'
  },

  // Ecosystem Specific Strings
  E: {
    HOMES: {
      NAME: '家园',
      NAME_EDIT: '家园',
      ADD_L: '点击 添加一个家',
      NO_SPACE_L: '没有空间',
      NO_SPACE_D: '您需要更多空间来添加新房屋。 你想购买更多空间吗？',
      ROOMS: '客房',
      APPEARANCE: '出现',
      FEATURES: '特征',
      GALLERY: '画廊',
      SERVICES: '服务',
      AGENTS: '代理',
      ROOMS_BEDROOMS: '卧室',
      ROOMS_BATHROOMS: '浴室',
      ROOMS_KITCHENS: '厨房',
      ROOMS_LIVING: '客厅',
      ROOMS_DINING: '餐厅',
      ROOMS_OTHER: '其他房间',
      TAG_MYHOME: '我的家',
      TAG_INTERESTED: '有兴趣',
      NO_LAND: '没有土地',
      _BEDS: '床 | 床 | 床',
      _BATHS: '浴 | 浴 | 浴',
      BEDS: '0张床 | 一张床 | {count}张床',
      BATHS: '0个浴室 | 1个浴室 | {count}个浴室',
      BEDROOMS: '没有卧室 | 一间卧室 | {count}间卧室',
      BATHROOMS: '没有浴室 | 一间浴室 | {count}间浴室',
      BATHROOMS_HALF: '没有一半 | 一半 | {count}个半',
      BATHROOMS_FULL: '没有完整 | 1满 | {count}完整',
      PREPARE_SPOT: '载入中...',
      PREPARE_SPOT_D: '为你的家准备一个地方。',
      ADD_HOME: '添加主页',
      ADD_YOUR_HOME: '加你的家',
      ENTER_YOUR_HOME_ADDRESS: '输入您的家庭住址',
      HOME_ADDRESS: '家庭地址',
      HOME_ADDRESS_D: '让我们先输入您的家庭住址。',
      SEARCHING: '搜索...',
      SEARCHING_D: '收集有关您家的信息。',
      REFINING_SEARCH: '精炼搜索......',
      REFINING_SEARCH_D: '输入其他详细信息，例如公寓号码（例如21B），以查找酒店。',
      EVALUATE: '评估...',
      EVALUATE_D: '根据市场评估您的房屋。',
      FOUND_HOME: '找到了家',
      FOUND_HOME_D: '大！ 你的家已被添加。',
      VERIFY_HOME: '验证你的家？',
      VERIFY_HOME_D: '特殊代码会发送到您的地址！',
      HISTORY: {
        TITLE: '历史',
        LABEL_TT: '查看历史记录'
      },
      RECORD: {
        TITLE: '公共记录',
        LABEL_TT: '查看公共记录'
      },
      MODIFY: {
        LABEL_TT: '更新回家'
      },
      VIEW: {
        LABEL_TT: '查看主页'
      },
      DELETE: {
        LABEL_TT: '搬回家',
        TITLE_QUERY: '搬回家',
        MESSAGE_QUERY: '你确定要删除这个房子吗？',
        DELETING: '搬家'
      },
      DETAILS: {
        STARTING_VALUE: '物业起始价值m',
        FEATURES: '特征',
        FINISHES: '饰面',
        FEATURES_FINISHES: '特色/饰面',
        MARKET_ADJUSTMENT: '市场调整'
      },
      AGE: {
        OWNERSHIP: '新主人 | 少于2年的所有权 | 拥有{count}年',
        YEARS: '新建筑 | 新建筑 | {count}年'
      }
    },
    BUSINESS: {
      NAME: '商业',
      NAME_EDIT: '商业',
      ADD_L: '点按即可添加业务',
      ADD_YOUR_PRODUCT: '添加您的业务',
      PREPARE_SPOT: '载入中...',
      PREPARE_SPOT_D: '为您的业务做准备。',
      ENTER_YOUR_PRODUCT_ADDRESS: '输入您的公司名称和地址',
      PRODUCT_ADDRESS: '商家名称',
      PRODUCT_ADDRESS_D: '首先输入您的公司名称和地址。',
      EDIT: {
        LABEL_TT: '更新通讯'
      }
    },
    FOODS: {
      NAME: '食物',
      NAME_EDIT: '食物',
      ADD_L: '通过点击添加菜单',
      BUSINESS: {
        NAME: '餐厅餐厅'
      },
      VIEW: {
        LABEL_TT: '视图'
      },
      MENU: '菜单',
      TEAM: '球队',
      ACTIVITY: '活动',
      ACTIVITY_TODAY: '今天的活动',
      PREFERENCES_CHECK: '首选项检查',
      PERSONALIZE: '个性化',
      PERSONALIZE_MENU: '个性化您的菜单',
      REFINE: '细化',
      ORDER_ADD: '添加到您的订单',
      ORDER_SEND: '发送订单',
      INGREDIENT_ADD: '添加成分',
      SIDE_CHOOSE: '选择一面',
      OFFERS: '优惠',
      EXPLORE: '探索',
      SALADS: '色拉',
      BOWLS: '碗类'
    },
    ANIMALS: {
      NAME: '动物',
      NAME_EDIT: '动物',
      ADD_L: '通过点击添加动物'
    }
  }
}
