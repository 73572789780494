import enUS from './en_us'
import esUS from './es_us'
import zhCN from './zh_cn'
import arSA from './ar_sa'

export default {
  enUS,
  esUS,
  zhCN,
  arSA
}
