import { compile } from 'vue-template-compiler';

// routing
export default [
  {
    path: '/',
    component: () => import('layouts/default'),
    children: [
      // root path
      {
        path: '/index.html',
        redirect: '/'
      },

      // landing page
      {
        path: '',
        name: ('WELCOME'),
        component: () => import('pages/index')
      },

      // channels
      // {
      //   path: '/channels',
      //   name: ('CHANNELS'),
      //   component: () => import('pages/channels')
      // },

      // callback (auth-process)
      {
        path: '/callback',
        alias: ['file:///callback', '/access_token'],
        name: ('AUTH.AUTHENTICATING'),
        component: () => import('pages/callback')
      },

      // logout
      {
        path: '/logout',
        name: ('AUTH.LOGOUT'),
        component: () => import('pages/logout')
      },

      // demo (new technologies)
      {
        path: '/business-demo',
        name: 'E.BUSINESS.NAME',
        meta: { authRequired: true },
        component: () => import('pages/products-business.demo')
      },
      {
        path: '/business-demo/:id',
        name: 'E.BUSINESS.NAME_EDIT',
        meta: { authRequired: true },
        component: () => import('pages/products-business.edit.demo')
      },
      {
        path: '/channel-demo/:uri',
        name: 'CHANNEL.LABEL_TT',
        component: () => import('pages/channel.product.demo')
      },

      // business (auth-required)
      {
        path: '/business',
        name: 'E.BUSINESS.NAME',
        meta: {
          authRequired: true
        },
        component: () => import('pages/products-business')
      },

      // business edit (auth-required)
      {
        path: '/business/:uri',
        name: 'E.BUSINESS.NAME_EDIT',
        meta: {
          authRequired: true
        },
        component: () => import('pages/products-business.edit')
      },

      // public channel
      {
        path: '/channel/:uri',
        name: 'CHANNEL.LABEL_TT',
        component: () => import('pages/channel.product')
      },

      // public listing
      {
        path: '/@:id',
        alias: '/nest/:id',
        name: 'NEST',
        component: () => import('pages/nest.channels')
      },

      {
        path: '/apps',
        name: 'Apps',
        component: () => import('pages/apps')
      },

      // nests (auth-required)
      {
        path: '/nests',
        name: 'NESTS',
        meta: {
          authRequired: true
        },
        component: () => import('pages/nests')
      },

      // login
      {
        path: '/login',
        name: 'GET_STARTED',
        component: () => import('pages/login')
      },

      // today (auth-required)
      {
        path: '/today',
        name: 'TODAY',
        meta: {
          authRequired: true
        },
        component: () => import('pages/today')
      },

      // notifications (auth-required)
      {
        path: '/notifications',
        name: 'NOTIFICATIONS',
        meta: {
          authRequired: true
        },
        component: () => import('pages/notifications')
      },

      // plans (auth-required)
      {
        path: '/plans',
        name: 'PLANS',
        meta: {
          authRequired: true
        },
        component: () => import('pages/plans')
      },

      // memberships (auth-required)
      {
        path: '/memberships',
        name: 'MEMBERSHIPS',
        meta: {
          authRequired: true
        },
        component: () => import('pages/plans')
      }
    ]
  },

  // channel widget
  {
    path: '/channel/:uri/widget',
    alias: '/embed/widget/:uri',
    name: 'CHANNEL.LABEL_TT',
    component: () => import('layouts/channel.product.widget')
  },

  // channel winglet (QR code)
  {
    path: '/channel/:uri/winglet',
    alias: '/embed/winglet/:uri',
    name: 'CHANNEL.LABEL_TT',
    component: () => import('layouts/channel.product.winglet')
  },

  // success wallet funding add
  {
    path: '/wallet/success',
    name: 'Wallet Success',
    component: () => import('pages/wallet.success')
  },

  // 404 - always leave this as last one
  {
    path: '*',
    component: () => import('pages/404')
  }
]
