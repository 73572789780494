export default {
  lang: 'es',
  // App: Common
  YES: 'Sí',
  NO: 'No',
  CANCEL: 'Cancelar',
  CLOSE: 'Cerrar',
  DONE: 'Hecho',
  OK: 'OK',
  EDIT: 'Editar',
  VIEW: 'Ver',
  USE: 'Usar',
  MORE: 'Más',
  ADD: 'Añadir',
  REMOVE: 'Eliminar',
  LEARN_MORE: 'Aprende más',
  GET_STARTED: 'Empezar',
  LOGGED_IN: 'Conectado',
  NOT_LOGGED_IN: 'No conectado',
  NOT_FOUND: 'Extraviado',
  LANGUAGE: 'Idioma',
  LOGOUT: 'Cerrar sesión',
  LOGOUT_MESSAGE: '¿Seguro que quieres salir?',
  HELLO_FRIEND: '¡Hola, amigo!',
  WELCOME_FRIEND: '¡Bienvenido, amigo!',
  HELLO_NAME: '¡{name}, amigo!',
  WELCOME: 'Bienvenido',
  PROGRESS: 'Progreso',
  COMPLETE: 'Completar',
  PROFILE: 'Perfil',
  SHARE: 'Compartir',
  VERIFY: 'Verificar',
  VERIFIED_REQUESTED: 'Verificación solicitada ...',
  VERIFIED_SENT: 'Verificación enviada ...',
  VERIFIED_EXPIRED: 'Verificación expirada',
  VERIFIED_VERIFIED: 'Verificado',
  VERIFIED: 'Verificado',
  VERIFIED_NOT: 'Aún no reclamado',
  LEGAL_RIGHTS: 'Todos los derechos reservados.',
  MORE_DETAIL: 'mas detalle',
  FIND: 'Encontrar',
  ABBR_EXAMPLE: 'p.ej.',
  OVERVIEW: 'Visión general',
  SUMMARY: 'Resumen',
  FOUND: '{amount} encontrados',
  NAVIGATE_BACK: 'Regresa',
  ANONYMOUS_FRIEND: 'amigo anónimo',
  APPEARANCE: 'Apariencia',
  APPEARANCE_DARKMODE: 'Modo oscuro',
  APPEARANCE_DARKMODE_INFO: 'Habilitar el modo oscuro todo el tiempo',
  CHANNEL: {
    LABEL_TT: 'Canal'
  },
  ORDERBY: {
    LABEL_TT: 'Ordenar por',
    ADD: 'Agregar servicio'
  },
  OPEN: 'Abierta',
  CLOSED: 'Cerrada',
  CONNECTING: 'Conectando...',
  ONLINE: {
    LABEL: 'En línea'
  },
  OFFLINE: {
    LABEL: 'Fuera de línea'
  },
  CALL: 'Call',
  AVAILABILITY: {
    LABEL: 'Disponibilidad',
    PARTIAL: 'Servicios parciales disponibles',
    FULL: 'Servicios completos disponibles',
    CALLS_YES: '¡Llamanos!',
    CALLS_NO: '¡No llames!'
  },
  LOYALTY: {
    LABEL: 'lealtad',
    LABEL_TT: 'lealtad'
  },
  SERVICES: {
    LABEL_TT: 'Servicios',
    ADD: 'Agregar servicio'
  },
  WIFI: {
    LABEL: 'Wi-Fi'
  },
  WAIT_TIME: {
    LABEL: 'Tiempo de espera',
    D: 'Comunique el tiempo promedio que toma antes de que se atienda un nuevo pedido.'
  },
  SERVICE_TIME: {
    LABEL: 'Tiempo de servicio',
    D: 'Comunique el tiempo promedio que tarda un pedido en ser atendido.'
  },
  OPERATIONS: {
    LABEL_TT: 'Operaciones'
  },
  OFFERS: {
    LABEL: 'Ofertas',
    LABEL_TT: 'Ofertas actuales',
    ADD: 'Añadir oferta'
  },
  TASKS: {
    LABEL_TT: 'Tareas',
    LABEL_ALT_TT: 'Que Hacer',
    ADD: 'Agregar una tarea'
  },
  REQUESTS: {
    LABEL_TT: 'Peticiones'
  },
  MEDIA: {
    PHOTOS: {
      ADD: 'Agregar una foto',
      DROP: 'Soltar foto',
      PROCESSING: 'Procesando foto'
    }
  },
  CHANGES: {
    LABEL_TT: 'Ver cambios'
  },
  PROFILES: {
    SWITCH: {
      LABEL_TT: 'cambiar interés',
      MESSAGE: '¿Desea cambiar su interés a {profile}?'
    }
  },
  CLAIM: {
    BUSINESS: 'Reclamar negocios'
  },

  ORDERS: {
    LABEL: 'Pedidos',
    ACCEPTING: 'Aceptar pedidos',
    NOT_ACCEPTING: 'No aceptar pedidos'
  },
  MINUTE: 'minuto',
  MINUTE_MIN: 'min',
  MINUTES_VAL: '-- | 1 minuto | {count} minutos',
  MINUTES_ATLEAST_VAL: '-- | al menos 1 minuto | al menos {count} minutos',

  LIVE: 'en vivo',
  COMMUNICATION: {
    LIVE: 'Comunicación en vivo',
    LAST: 'Última comunicación conocida'
  },

  FOODS: {
    LABEL_TT: 'Alimentos'
  },
  DINE_IN: {
    LABEL: 'Cenar en'
  },
  AUTO: {
    LABEL_TT: 'Auto'
  },
  BAYS: {
    LABEL: 'bahías',
    COUNT: '0 bahías | 1 bahías | {count} bahías'
  },
  ALL: 'Todos',
  NONE: 'Ninguna',
  BUSINESS: {
    LABEL_TT: 'Negocio'
  },
  PICKUP: {
    LABEL_TT: 'Recogiendo'
  },
  PARKING: {
    LABEL: 'Estacionamiento'
  },
  DRIVETHRU: {
    LABEL: 'servicio al carro'
  },
  WALKINS: {
    LABEL: 'Sin cita'
  },
  CURBSIDE: {
    LABEL: 'Acera'
  },
  DRIVETHRU_ORDERING: {
    LABEL: 'servicio al carro'
  },
  ACCEPTS: {
    LABEL_TT: 'Acepta'
  },
  CASH: {
    LABEL: 'Efectivo'
  },
  CREDITCARD: {
    LABEL: 'Tarjeta de crédito'
  },
  CRPTO: {
    LABEL: 'Criptomoneda'
  },
  APPLEPAY: {
    LABEL: 'Apple Pay'
  },
  UBEREATS: {
    LABEL: 'Uber Eats'
  },
  DOORDASH: {
    LABEL: 'DoorDash'
  },
  POSTMATES: {
    LABEL: 'Postmates'
  },
  GRUBHUB: {
    LABEL: 'Grubhub'
  },
  HOMES: {
    LABEL_TT: 'Casas'
  },
  AMENITIES: {
    LABEL: 'Comodidades'
  },
  AVAILABLE_PROPERTIES: {
    LABEL: 'Disponibilidad'
  },
  DEPARTMENT_STORE: {
    LABEL_TT: 'Departamento'
  },
  INVENTORY: {
    LABEL: 'Inventario',
    FULL: 'Llena',
    PARTIAL: 'Parcial',
    LOW: 'Baja'
  },
  ANIMALS: {
    LABEL_TT: 'Animales'
  },
  TELEHEALTH: {
    LABEL: 'Telesalud'
  },
  WEBSITE: {
    LABEL: 'Sitio web',
    OFFICIAL: 'Página web oficial'
  },
  MENU: {
    LABEL: 'Menú'
  },
  PHONE: {
    LABEL: 'Teléfono'
  },
  IDENTITY: {
    LABEL: 'Reclamación'
  },

  // App: Version
  VERSION: {
    L: 'versión',
    LATEST: 'Estas actualizado!',
    VIEW_RELEASE_NOTES: 'Ver notas de la versión',
    CHECK_UPDATES: 'Buscar actualizaciones'
  },

  // App: Support
  SUPPORT: {
    L: 'apoyo',
    D: '¡Estamos aquí para ayudar!',
    SYSTEM_STATUS_OK: 'Sistemas en funcionamiento.',
    VISIT_FAQ: 'Visita nuestras preguntas frecuentes',
    REPORT_ISSUE: 'Reportar un problema'
  },

  // App: Credits
  CREDITS: {
    L: 'creditos',
    T: '¡Gracias!',
    D: 'Es con su amor y apoyo que continúa ayudándonos a crecer y transformarnos.',
    L_SUPPORTERS: 'simpatizantes',
    L_ADVISORS: 'asesores',
    L_PARTNERS: 'fogonadura',
    L_TECHNOLOGIES: 'tecnologías'
  },

  // App: Errors
  ERRORS: {
    E404: {
      L: 'La página que estás buscando no se puede encontrar.',
      D: 'no fue encontrado.'
    }
  },

  // App: Session
  SESSION: {
    ENDED: {
      L: 'Sesión finalizada.',
      D: 'Inicia sesión para iniciar una nueva sesión.'
    }
  },

  // App: Pull-to-Refresh
  PTR: {
    RELEASE: 'Soltar para actualizar',
    REFRESH: 'Actualizando...',
    PULL: 'Halar hacia abajo para actualizar'
  },

  // App: Drawer
  DRAWER: {
    HEADER: {
      ACCOUNT: 'Cuenta',
      APP: 'Aplicación',
      SOCIAL: 'Social',
      LEGAL: 'Legal'
    },
    ITEM: {
      PROFILES: {
        L: 'Estilo de vida',
        D: 'Administra tus intereses'
      },
      PREFERENCES: {
        L: 'Preferencias',
        D: 'Lenguaje, apariencia y más ...'
      },
      GET_STARTED: {
        L: 'Empezar',
        D: 'Iniciar o iniciar sesión para continuar'
      },
      WALLET: {
        L: 'Billetera',
        D: 'Métodos de pago'
      },
      LOGOUT: {
        L: 'Logout',
        D: 'Guardar y salir de la aplicación'
      },
      ABOUT: {
        L: 'Acerca de',
        D: 'Versión y soporte'
      },
      UPDATES: {
        L: 'Notas de lanzamiento',
        D: 'Liberar cambios y actualizaciones'
      },
      RELEASE: {
        L: 'Buscar actualizaciones ...',
        D: 'Actualizar la aplicación a la última versión'
      },
      HELP: {
        L: 'Ayuda',
        D: 'Guías y preguntas frecuentes'
      },
      FEEDBACK: {
        L: 'Realimentación',
        D: 'Nos encanta saber de ti'
      },
      PRIVACY: {
        L: 'Política de privacidad',
        D: 'Nos tomamos la privacidad muy en serio'
      }
    }
  },

  // App: Updates
  UPDATES: {
    TITLE_CHECK: 'Comprobando actualizaciones ...',
    TITLE_NEW: 'Nueva actualización',
    TITLE_NO_UPDATES: 'Ninguna actualización',
    MESSAGE_NEW: 'Una nueva actualización está disponible para descargar.',
    UPDATE: 'Actualizar',
    NOT_NOW: 'Ahora no'
  },

  // App: Authentication
  AUTH: {
    AUTHENTICATION: 'Autenticando...',
    LOGOUT: 'Cerrando sesión...'
  },

  // Ecosystem Specific String
  E: {
    HOMES: {
      NAME: 'hogares',
      NAMES_EDIT: 'hogares',
      ADD_L: 'Agrega una casa tocando',
      NO_SPACE_L: 'sin espacio',
      NO_SPACE_D: 'Necesita más espacio para agregar un nuevo hogar. ¿Te gustaría comprar más espacio?',
      ROOMS: 'Habitaciones',
      APPEARANCE: 'Apariencia',
      FEATURES: 'Caracteristicas',
      GALLERY: 'Galería',
      SERVICES: 'Servicios',
      AGENTS: 'Agentes',
      ROOMS_BEDROOMS: 'Dormitorios',
      ROOMS_BATHROOMS: 'Baños',
      ROOMS_KITCHENS: 'Cocinas',
      ROOMS_LIVING: 'Salas',
      ROOMS_DINING: 'Comedor',
      ROOMS_OTHER: 'Otras habitaciones',
      TAG_MYHOME: 'Mi casas',
      TAG_INTERESTED: 'Interesada',
      NO_LAND: 'Sin tierra',
      _BEDS: 'camas | cama | camas',
      _BATHS: 'baños | baño | baños',
      BEDS: '0 camas | 1 cama | {count} camas',
      BATHS: '0 baños | 1 baño | {count} baños',
      BEDROOMS: 'Sin dormitorios | 1 dormitorio | {count} dormitorios',
      BATHROOMS: 'Sin baños | 1 baño | {count} baños',
      BATHROOMS_HALF: 'no mitad | 1 mitad | {count} mitad',
      BATHROOMS_FULL: 'no lleno | 1 lleno | {count} llenos',
      PREPARE_SPOT: 'Cargando...',
      PREPARE_SPOT_D: 'Preparando un lugar para tu hogar.',
      ADD_HOME: 'Añadir Inicio',
      ADD_YOUR_HOME: 'Agrega tu casa',
      ENTER_YOUR_HOME_ADDRESS: 'Ingrese la dirección de su casa',
      HOME_ADDRESS: 'Direccion de Casa',
      HOME_ADDRESS_D: 'Comencemos ingresando la dirección de su casa.',
      SEARCHING: 'Buscando...',
      SEARCHING_D: 'Recopilación de información sobre su hogar.',
      REFINING_SEARCH: 'Búsqueda refinada ...',
      REFINING_SEARCH_D: 'Ingrese detalles adicionales, como el número de apartamento (ej. 21B), para encontrar la propiedad.',
      EVALUATE: 'Evaluando ...',
      EVALUATE_D: 'Evaluando su vivienda en función del mercado.',
      FOUND_HOME: 'Casa Encontrada',
      FOUND_HOME_D: '¡Genial! Ahora vamos a estimar el valor de su casa.',
      VERIFY_HOME: '¿Verifica tu casa?',
      VERIFY_HOME_D: 'Un código especial es enviado a su dirección!',
      HISTORY: {
        TITLE: 'Historia',
        LABEL_TT: 'Ver historial'
      },
      RECORD: {
        TITLE: 'Registro Público',
        LABEL_TT: 'Ver registro público'
      },
      MODIFY: {
        LABEL_TT: 'Actualizar inicio'
      },
      VIEW: {
        LABEL_TT: 'Ver casa'
      },
      DELETE: {
        LABEL_TT: 'Quitar casa',
        TITLE_QUERY: 'Quitar casa',
        MESSAGE_QUERY: '¿Estás seguro de que quieres eliminar esta casa?',
        DELETING: 'Quitar casa'
      },
      DETAILS: {
        STARTING_VALUE: 'Valor de inicio de la propiedad',
        FEATURES: 'Características',
        FINISHES: 'Acabados',
        FEATURES_FINISHES: 'Características / Acabados',
        MARKET_ADJUSTMENT: 'Ajuste de mercado'
      },
      AGE: {
        OWNERSHIP: 'nuevo dueño | menos de 2 años de propiedad | {count} years of ownership',
        YEARS: 'nueva construcción | nueva construcción | {count} años'
      }
    },
    BUSINESS: {
      NAME: 'business',
      NAME_EDIT: 'business',
      ADD_L: 'Agregue un negocio tocando',
      ADD_YOUR_PRODUCT: 'Agrega tu negocio',
      PREPARE_SPOT: 'Cargando...',
      PREPARE_SPOT_D: 'Preparando un lugar para su negocio.',
      ENTER_YOUR_PRODUCT_ADDRESS: 'Ingrese el nombre y la dirección de su empresa',
      PRODUCT_ADDRESS: 'Nombre del Negocio',
      PRODUCT_ADDRESS_D: 'Comencemos ingresando el nombre y la dirección de su empresa.',
      EDIT: {
        LABEL_TT: 'Actualizar la comunicación'
      }
    },
    FOODS: {
      NAME: 'foods',
      NAME_EDIT: 'foods',
      ADD_L: 'Agregue un menú tocando',
      BUSINESS: {
        NAME: 'Restaurante'
      },
      VIEW: {
        LABEL_TT: 'Ver'
      },
      MENU: 'Menú',
      TEAM: 'Equipo',
      ACTIVITY: 'Actividad',
      ACTIVITY_TODAY: 'Actividad de hoy',
      PREFERENCES_CHECK: 'Verificación de preferencias',
      PERSONALIZE: 'Personalizar',
      PERSONALIZE_MENU: 'Personaliza tu menú',
      REFINE: 'Refinar',
      ORDER_ADD: 'Añadir a su pedido',
      ORDER_SEND: 'Enviar orden',
      INGREDIENT_ADD: 'Agregar un ingrediente',
      SIDE_CHOOSE: 'Elige un lado',
      OFFERS: 'Ofertas',
      EXPLORE: 'Explorar',
      SALADS: 'Ensaladas',
      BOWLS: 'Bochas'
    },
    ANIMALS: {
      NAME: 'animales',
      NAME_EDIT: 'animales',
      ADD_L: 'Agrega un animal tocando'
    }
  }
}
