
export const updateLanguage = (state, lang) => {
  state.lang = lang
  localStorage.setItem('lang', lang)
}

export const updateDarkMode = (state, darkmode) => {
  state.darkmode = darkmode
  localStorage.setItem('darkmode', darkmode)
}

export const updatePreferencesDevPresenseState = (state, devPresenseEnabled) => {
  state.preferences.dev_presense.enabled = devPresenseEnabled
  localStorage.setItem('dev_presense_enabled', devPresenseEnabled)
}

export const updatePreferencesBrandingState = (state, brandingEnabled) => {
  state.preferences.branding.enabled = brandingEnabled
  localStorage.setItem('branding_enabled', brandingEnabled)
}

export const updatePreferencesVoiceState = (state, voiceEnabled) => {
  state.preferences.voice.enabled = voiceEnabled
  localStorage.setItem('voice_enabled', voiceEnabled)
}

export const updatePreferencesSoundState = (state, soundEnabled) => {
  state.preferences.sound.enabled = soundEnabled
  localStorage.setItem('sound_enabled', soundEnabled)
}

export const updatePreferencesDisplayGroupsDescription = (state, displayGroupsDescriptionMode) => {
  state.preferences.display.groups.description = displayGroupsDescriptionMode || false
  localStorage.setItem('display_groups_description', displayGroupsDescriptionMode)
}

export const updatePreferencesDisplayCardsMode = (state, displayCardsMode) => {
  state.preferences.display.cards.mode = displayCardsMode || false
  localStorage.setItem('display_cards_mode', displayCardsMode)
}

export const updatePreferencesDisplayEffectsState = (state, displayEffectsEnabled) => {
  state.preferences.display.effects.enabled = displayEffectsEnabled
  localStorage.setItem('display_effects_enabled', displayEffectsEnabled)
}

export const updateAccountWalletUsDollarsAmount = (state, amount) => {
  state.wallet.us_dollars.amount = amount
  localStorage.setItem('account_wallet_us_dollars', amount)
}

export const updateRequests = (state, requests) => {
  state.requests = requests
  localStorage.setItem('requests')
}

export const updateInit = (state, isInit) => {
  state.init = isInit
}

export const updateProducts = (state, products) => {
  state.products.updated = (new Date()).getTime()
  state.products.list = products.list || []
  state.products.group = products.group || {}
}

export const updateChannels = (state, channels) => {
  state.channels.updated = (new Date()).getTime()
  state.channels.list = channels.list || []
  state.channels.group = channels.group || {}
}

export const updateProductPayload = (state, productIndex, payload) => {
  state.products.list[productIndex].data.payload = payload
}

export const updateName = (state, name) => {
  state.name = name || null
  if (name === null) {
    localStorage.removeItem('name')
  } else {
    localStorage.setItem('name', name)
  }
}

export const updateFunds = (state, funds) => {
  state.funds = funds || 0
  if (funds === null) {
    localStorage.removeItem('funds')
  } else {
    localStorage.setItem('funds', funds)
  }
}

export const updateCurrency = (state, currency) => {
  state.currency = currency || 0
  if (currency === null) {
    localStorage.removeItem('currency')
  } else {
    localStorage.setItem('currency', currency)
  }
}

export const updateIO = (state, io) => {
  state.io.id = io.id || null
  state.io.idx = io.idx || null
  state.io.status = io.status || null
  state.io.name = io.name || null
  state.io.phone = io.phone || null
  state.io.email = io.email || null
  state.io.email_verified = io.email_verified || null
  state.io.picture = io.picture || null
  state.io.funds = io.funds || null
  state.io.currency = io.currency || null
  state.io.ecosystem_id = io.ecosystem_id || null
  state.io.ecosystems_users_id = io.ecosystems_users_id || null
  state.io.ecosystems_users_type = io.ecosystems_users_type || 'OWNER'
  state.io.io_access_token = io.io_access_token || null
  state.io.io_session_token = io.io_session_token || null

  if (io.id === null) {
    localStorage.removeItem('id')
  } else {
    localStorage.setItem('id', io.id)
  }

  if (io.idx === null) {
    localStorage.removeItem('idx')
  } else {
    localStorage.setItem('idx', io.idx)
  }

  if (io.status === null) {
    localStorage.removeItem('status')
  } else {
    localStorage.setItem('status', io.status)
  }

  if (io.name === null) {
    localStorage.removeItem('name')
  } else {
    localStorage.setItem('name', io.name)
  }

  if (io.phone === null) {
    localStorage.removeItem('phone')
  } else {
    localStorage.setItem('phone', io.phone)
  }

  if (io.email === null) {
    localStorage.removeItem('email')
  } else {
    localStorage.setItem('email', io.email)
  }

  if (io.email_verified === null) {
    localStorage.removeItem('email_verified')
  } else {
    localStorage.setItem('email_verified', io.email_verified)
  }

  if (io.picture === null) {
    localStorage.removeItem('picture')
  } else {
    localStorage.setItem('picture', io.picture)
  }

  if (io.funds === null) {
    localStorage.removeItem('funds')
  } else {
    localStorage.setItem('funds', io.funds)
  }

  if (io.currency === null) {
    localStorage.removeItem('currency')
  } else {
    localStorage.setItem('currency', io.currency)
  }

  if (io.ecosystem_id === null) {
    localStorage.removeItem('ecosystem_id')
  } else {
    localStorage.setItem('ecosystem_id', io.ecosystem_id)
  }

  if (io.ecosystems_users_id === null) {
    localStorage.removeItem('ecosystems_users_id')
  } else {
    localStorage.setItem('ecosystems_users_id', io.ecosystems_users_id)
  }

  if (io.ecosystems_users_type === null) {
    localStorage.removeItem('ecosystems_users_type')
  } else {
    localStorage.setItem('ecosystems_users_type', io.ecosystems_users_type)
  }

  if (io.io_access_token === null) {
    localStorage.removeItem('io_access_token')
  } else {
    localStorage.setItem('io_access_token', io.io_access_token)
  }

  if (io.io_session_token === null) {
    localStorage.removeItem('io_session_token')
  } else {
    localStorage.setItem('io_session_token', io.io_session_token)
  }
}
export const updateIOSessionToken = (state, token) => {
  state.io.io_session_token = token
  if (token === null) {
    localStorage.removeItem('io_session_token')
  } else {
    localStorage.setItem('io_session_token', token)
  }
}
export const updateIOAccessToken = (state, token) => {
  state.io.io_access_token = token
  if (token === null) {
    localStorage.removeItem('io_access_token')
  } else {
    localStorage.setItem('io_access_token', token)
  }
}

export const clearIO = (state) => {
  updateIO(state, {
    id: null,
    idx: null,
    status: null,
    ecosystem_id: null,
    ecosystems_users_id: null,
    ecosystems_users_type: null,
    io_access_token: null,
    io_session_token: null
  })
}
export const clearIOAccessToken = (state) => {
  state.io.io_access_token = null
  localStorage.removeItem('io_access_token')
}
