var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "moveup-disabled", attrs: { id: "q-app" } },
    [
      _c("q-ajax-bar", {
        ref: "bar",
        attrs: {
          position: "top",
          size: "4px",
          color: "primary",
          delay: 0,
          "skip-hijack-2": ""
        }
      }),
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class": "animated fadeIn",
            "leave-active-class": "animated fadeOut",
            mode: "out-in",
            duration: 300
          },
          on: { leave: _vm.resetScroll }
        },
        [
          _c("router-view", {
            on: {
              startAjaxBar: _vm.onStartAjaxBar,
              stopAjaxBar: _vm.onStopAjaxBar
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }