import auth0 from 'auth0-js'
import EventEmitter from 'eventemitter3'
import router from './../router'

import store from './../store'

import { axiosLIO } from 'plugins/axios'

import { magicLink } from 'plugins/magic'

// import LogRocket from 'logrocket'
// LogRocket.init('lfyzov/letsbutterflyapp')

function randomString (length) {
  let charset = '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._~', result = []
  while (length > 0) {
    window.crypto.getRandomValues(new Uint8Array(16)).forEach((c) => {
      if (!length) return
      if (c < charset.length) {
        result.push(charset[c])
        length--
      }
    })
  }
  return result.join('')
}
// let nonce = randomString(40), state = randomString(20)
// localStorage.setItem('nonce', nonce)
// localStorage.setItem('state', state)
let redirectUrl = [window.location.protocol, '//', window.location.host, '/callback'].join('')

import { Platform } from 'quasar'
if (Platform.is.electron) {
  // redirectUrl = 'file:///'
  redirectUrl = 'file:///callback'
  console.log(`[PLATFORM::Electron] ${redirectUrl}`)
}
console.log(__dirname, __filename)
console.log('conf. %s', redirectUrl)

export default class AuthService {
  authenticated = this.isAuthenticated()
  authNotifier = new EventEmitter()
  constructor () {
    this.login = this.login.bind(this)
    this.setSession = this.setSession.bind(this)
    this.logout = this.logout.bind(this)
    this.isAuthenticated = this.isAuthenticated.bind(this)
  }
  auth0 = new auth0.WebAuth({
    domain: 'auth.letsbutterfly.io',
    clientID: '05xQy1dmz2IUDuYYCL08cltO9BMinnOm',
    redirectUri: redirectUrl,
    responseType: 'token id_token',
    allowedConnections: 'sms',
    connection: 'sms'
  })
  tokenRenewalTimeout = null
  login () {
    this.auth0.authorize({
      ui_locales: document.documentElement.getAttribute('lang')
    })
  }
  handleAuthentication () {
    console.log(':: magicLink AuthServices ::')
    const url = new URL(window.location.href)
    const idToken = url.searchParams.get('didt') || url.searchParams.get('magic_credential')
    console.log(`id_token = (${idToken.length}) ${idToken}`)
    if (idToken) {
      this.setSession({
        expiresIn: 99999,
        accessToken: randomString(20), // crypto.randomUUID().replace(/-/g, ''),
        idToken: idToken
      })
      // get
      axiosLIO.post(`/acquire`, {
        id_token: idToken
      }).then((response) => {
        console.log(response)

        if (response.status === 200) {
          var d = response.data.data
          console.log('======================')
          console.log(d)
          try {
            // check if LIO authentication is valid
            if (d.auth_verify.valid) {
              // store io data
              this.setIO(d.user)
            }
          } catch (e) {}
        }
        setTimeout(() => {
          var tkn = randomString(20) // crypto.randomUUID().replace(/-/g, '')
          // check if LTS is set
          var lts = localStorage.getItem('lts')
          if (lts) {
            router.push('/channel/' + lts + '/?r=' + tkn)
          } else {
            router.replace('/?r=' + tkn)
          }
          // console.log(this.$store.state.app.io.ecosystem_id)
        }, 1400)
      })
    }
  }
  _handleAuthentication () {
    var _this = this
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult)

        // run auth on server with this token
        this.auth0.client.userInfo(authResult.accessToken, function (err, user) {
          if (err) {
            console.log('There was an error retrieving your profile: ' + err.message)
            console.log('err', err)
          } else {
            // grab the idToken and verify it with our LIO
            axiosLIO.post(`/auth`, {
              'id_token': authResult.idToken
            }).then((response) => {
              if (response.status === 200) {
                var d = response.data.data
                console.log('======================')
                console.log(d)
                try {
                  // check if LIO authentication is valid
                  if (d.auth_verify.valid) {
                    // store io data
                    _this.setIO(d.user)
                    // check the status of the user
                    if (d.user && d.user.status === 'INIT_AUTH') {
                      // TODO: provide more details
                      // Email (optional) + State (required)
                    }
                    // intiate a LR id
                    // LogRocket.identify(d.user.id, {
                    //   id: d.user.id,
                    //   ecosystem_usersId: d.user.ecosystem_usersId,
                    //   io_access_token: d.user.io_access_token,
                    //   io_session_token: d.user.io_session_token,
                    //   name: d.auth_verify.claims.name
                    // })
                  }
                } catch (e) {
                  console.log(e)
                }
              }
            }).catch(() => {})
          }
          setTimeout(() => {
            router.replace('/')
            // console.log(_this.$store.state.app.io.ecosystem_id)
            // router.replace('/' + _this.$store.state.app.io.ecosystem_id)
          }, 100)
        })
      } else if (err) {
        router.replace('/')
        console.log(err)
        // alert(`Error: ${err.error}. Check the console for further details.`)
      }
    })
  }
  setIO (ioResult) {
    store.commit('app/updateIO', ioResult)
    // axiosLIO.defaults.params = {}
    // axiosLIO.defaults.params['io_access_token'] = store.state.app.io.io_access_token
    // axiosLIO.defaults.params['io_session_token'] = store.state.app.io.io_session_token
  }
  renewToken () {
    this.auth0.checkSession({}, (err, result) => {
      if (err) {
        console.log(err)
      } else {
        this.setSession(result)
      }
    })
  }
  scheduleRenewal () {
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'))
    let delay = expiresAt - Date.now()
    if (delay > 0) {
      // console.log(`tokenRenewal scheduled in ${delay}ms`)
      this.tokenRenewalTimeout = setTimeout(() => {
        this.renewToken()
      }, delay)
    }
  }
  setSession (authResult) {
    // Set the time that the access token will expire at
    // console.log(`auth expires in: ${authResult.expiresIn}`)
    const now = new Date()
    now.setFullYear(now.getFullYear() + 1)
    const timestampOneYearFromNow = now.getTime()
    let expiresAt = JSON.stringify(timestampOneYearFromNow)
    localStorage.setItem('io_access_token', authResult.accessToken)
    localStorage.setItem('id_token', authResult.idToken)
    localStorage.setItem('expires_at', expiresAt)
    this.authNotifier.emit('authChange', { authenticated: true })
    this.scheduleRenewal()
  }
  setUser (userResult) {

  }
  logout () {
    // clear IO
    store.commit('app/clearIOAccessToken')
    // store.commit('app/clearIO')
    // clear auth_data
    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_at')
    this.authNotifier.emit('authChange', false)
    clearTimeout(this.tokenRenewalTimeout)
    // log out of magic and backend
    this.logoutAll()
  }
  isAuthenticated () {
    // return true
    // Check whether the current time is past the
    // access token's expiry time
    let expiresAtItem = localStorage.getItem('expires_at')
    console.log(':: isAuthenticated() :: DEBUG')
    console.log(expiresAtItem)
    if (!expiresAtItem) {
      return false
    }

    let timeNow = new Date().getTime()
    let expiresAt = JSON.parse(localStorage.getItem('expires_at')) || 0
    if (timeNow >= expiresAt) {
      // update expiresAt
      const now = new Date()
      now.setFullYear(now.getFullYear() + 1)
      const timestampOneYearFromNow = now.getTime()
      expiresAt = JSON.stringify(timestampOneYearFromNow)
      localStorage.setItem('expires_at', expiresAt)
    }
    return timeNow < expiresAt
  }
  async logoutAll () {
    await magicLink.user.logout()
    // logout from backend
    axiosLIO.post('/logout')
    var tkn = randomString(20) // crypto.randomUUID().replace(/-/g, '')
    // check if LTS is set
    var ltc = localStorage.getItem('ltc')
    if (ltc) {
      localStorage.removeItem('ltc')
      router.push('/channel/' + ltc + '/?r=' + tkn)
    } else {
      router.push('/?r=' + tkn)
    }
  }
}
