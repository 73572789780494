export default {
  lang: 'ar',
  rtl: true, // App: Common
  YES: 'نعم',
  NO: 'لا',
  CANCEL: 'إلغاء',
  CLOSE: 'نهاية',
  DONE: 'منجز',
  OK: 'حسناً',
  EDIT: 'تعديل',
  VIEW: 'افتح',
  USE: 'استخدم',
  MORE: 'إضافي',
  ADD: 'أضف',
  REMOVE: 'إزالة',
  LEARN_MORE: 'أعرف أكثر',
  GET_STARTED: 'ابدأ',
  LOGGED_IN: 'تسجيل الدخول',
  NOT_LOGGED_IN: 'لم تقم بتسجيل الدخول',
  NOT_FOUND: 'غير موجود',
  LANGUAGE: 'لغة',
  LOGOUT: 'تسجيل خروج',
  LOGOUT_MESSAGE: 'هل أنت متأكد من أنك تريد الخروج؟',
  HELLO_FRIEND: 'مرحبا، صديقي!',
  WELCOME_FRIEND: 'مرحبا، صديقي!',
  HELLO_NAME: '{name}، مرحبا،!',
  WELCOME: 'أهلا بك',
  PROGRESS: 'تقدم',
  COMPLETE: 'اكتمال',
  PROFILE: 'الملف الشخصي',
  SHARE: 'شارك',
  VERIFY: 'تحقق',
  VERIFIED_REQUESTED: 'طلب التحقق ...',
  VERIFIED_SENT: 'تم إرسال التحقق ...',
  VERIFIED_EXPIRED: 'انتهت صلاحية التحقق',
  VERIFIED_VERIFIED: 'تم التحقق',
  VERIFIED: 'تم التحقق',
  VERIFIED_NOT: 'لم تدع بعد',
  LEGAL_RIGHTS: 'كل الحقوق محفوظة.',
  MORE_DETAIL: 'تفاصيل اكثر',
  FIND: 'تجد',
  ABBR_EXAMPLE: 'e.g.',
  OVERVIEW: 'نظرة عامة',
  SUMMARY: 'ملخص',
  FOUND: 'تم العثور على {amount}',
  NAVIGATE_BACK: 'عد',
  ANONYMOUS_FRIEND: 'صديق مجهول',
  APPEARANCE: 'مظهر خارجي',
  APPEARANCE_DARKMODE: 'الوضع الداكن',
  APPEARANCE_DARKMODE_INFO: 'قم بتمكين الوضع المظلم طوال الوقت',
  CHANNEL: {
    LABEL_TT: 'قناة'
  },
  ORDERBY: {
    LABEL_TT: 'ترتيب حسب',
    ADD: 'أضف خدمة'
  },
  OPEN: 'مفتوح',
  CLOSED: 'مغلق',
  CONNECTING: 'توصيل...',
  ONLINE: {
    LABEL: 'عبر الانترنت'
  },
  OFFLINE: {
    LABEL: 'غير متصل'
  },
  CALL: 'Call',
  AVAILABILITY: {
    LABEL: 'التوفر',
    PARTIAL: 'الخدمات الجزئية المتاحة',
    FULL: 'الخدمات الكاملة المتاحة',
    CALLS_YES: 'Give us a call!',
    CALLS_NO: 'Do not call'
  },
  LOYALTY: {
    LABEL: 'وفاء',
    LABEL_TT: 'وفاء'
  },
  SERVICES: {
    LABEL_TT: 'خدمات',
    ADD: 'أضف خدمة'
  },
  WIFI: {
    LABEL: 'Wi-Fi'
  },
  WAIT_TIME: {
    LABEL: 'وقت الانتظار',
    D: 'أبلغ متوسط الوقت الذي تستغرقه قبل تقديم طلب جديد.'
  },
  SERVICE_TIME: {
    LABEL: 'وقت الخدمة',
    D: 'أبلغ متوسط الوقت الذي يستغرقه الأمر لخدمة الطلب.'
  },
  OPERATIONS: {
    LABEL_TT: 'عمليات'
  },
  OFFERS: {
    LABEL: 'عروض',
    LABEL_TT: 'العروض الحالية',
    ADD: 'أضف عرض'
  },
  TASKS: {
    LABEL_TT: 'مهام',
    LABEL_ALT_TT: 'لكى يفعل',
    ADD: 'أضف مهمة'
  },
  REQUESTS: {
    LABEL_TT: 'طلبات'
  },
  MEDIA: {
    PHOTOS: {
      ADD: 'إضافة صورة',
      DROP: 'إسقاط صورة',
      PROCESSING: 'معالجة الصور'
    }
  },
  CHANGES: {
    LABEL_TT: 'عرض التغييرات'
  },
  PROFILES: {
    SWITCH: {
      LABEL_TT: 'تغيير الاهتمام',
      MESSAGE: 'هل تريد تحويل اهتمامك إلى {profile}؟'
    }
  },
  CLAIM: {
    BUSINESS: 'المطالبة التجارية'
  },

  ORDERS: {
    LABEL: 'الطلبات',
    ACCEPTING: 'قبول الطلبات',
    NOT_ACCEPTING: 'عدم قبول الطلبات'
  },
  MINUTE: 'دقيقة',
  MINUTE_MIN: 'min',
  MINUTES_VAL: '-- | 1 دقيقة | {count} دقيقة',
  MINUTES_ATLEAST_VAL: '-- | دقيقة واحدة على الأقل | {count} دقيقة على الأقل',

  LIVE: 'بث مباشر',
  COMMUNICATION: {
    LIVE: 'التواصل المباشر',
    LAST: 'آخر اتصال معروف'
  },

  FOODS: {
    LABEL_TT: 'الأطعمة'
  },
  DINE_IN: {
    LABEL: 'تناول الطعام'
  },
  AUTO: {
    LABEL_TT: 'السيارات'
  },
  BAYS: {
    LABEL: 'خلجان السيارات',
    COUNT: '0 bays | 1 bay | {count} bays'
  },
  ALL: 'الكل',
  NONE: 'لا يوجد',
  BUSINESS: {
    LABEL_TT: 'الأعمال'
  },
  PICKUP: {
    LABEL_TT: 'التقاط'
  },
  PARKING: {
    LABEL: 'موقف سيارات'
  },
  DRIVETHRU: {
    LABEL: 'القيادة من خلالh'
  },
  WALKINS: {
    LABEL: 'المشي'
  },
  CURBSIDE: {
    LABEL: 'رصيف'
  },
  DRIVETHRU_ORDERING: {
    LABEL: 'القيادة من خلال'
  },
  ACCEPTS: {
    LABEL_TT: 'يقبل'
  },
  CASH: {
    LABEL: 'نقدا'
  },
  CREDITCARD: {
    LABEL: 'بطاقة ائتمان'
  },
  CRPTO: {
    LABEL: 'عملة مشفرة'
  },
  APPLEPAY: {
    LABEL: 'Apple Pay'
  },
  UBEREATS: {
    LABEL: 'Uber Eats'
  },
  DOORDASH: {
    LABEL: 'DoorDash'
  },
  POSTMATES: {
    LABEL: 'Postmates'
  },
  GRUBHUB: {
    LABEL: 'Grubhub'
  },
  HOMES: {
    LABEL_TT: 'منازل'
  },
  AMENITIES: {
    LABEL: 'وسائل الراحة'
  },
  AVAILABLE: 'متوفر',
  AVAILABLE_PROPERTIES: {
    LABEL: 'التوفر'
  },
  DEPARTMENT_STORE: {
    LABEL_TT: 'القسم'
  },
  INVENTORY: {
    LABEL: 'الجرد',
    FULL: 'كامل',
    PARTIAL: 'جزئي',
    LOW: 'منخفض'
  },
  ANIMALS: {
    LABEL_TT: 'الحيوانات'
  },
  TELEHEALTH: {
    LABEL: 'الخدمات الصحية عن بعد'
  },
  WEBSITE: {
    LABEL: 'الموقع',
    OFFICIAL: 'الموقع الرسمي'
  },
  MENU: {
    LABEL: 'القائمة'
  },
  PHONE: {
    LABEL: 'هاتف'
  },
  IDENTITY: {
    LABEL: 'مطالبة'
  },

  // App: Version
  VERSION: {
    L: 'الإصدار',
    LATEST: 'كنت حتى الآن!',
    VIEW_RELEASE_NOTES: 'عرض ملاحظات الإصدار',
    CHECK_UPDATES: 'تحقق من وجود تحديثات'
  },

  // App: Support
  SUPPORT: {
    L: 'الدعم',
    D: 'نحن هنا للمساعدة!',
    SYSTEM_STATUS_OK: 'تشغيل الأنظمة.',
    VISIT_FAQ: 'قم بزيارة الأسئلة الشائعة',
    REPORT_ISSUE: 'بلغ عن خطأ'
  },

  // App: Credits
  CREDITS: {
    L: 'الاعتمادات',
    T: 'شكرا لكم!',
    D: 'مع حبك ودعمك الذي يستمر في مساعدتنا على النمو والتحول.',
    L_SUPPORTERS: 'أنصار',
    L_ADVISORS: 'المستشارين',
    L_PARTNERS: 'شركاء',
    L_TECHNOLOGIES: 'التقنيات'
  },

  // App: Errors
  ERRORS: {
    E404: {
      L: 'لا يمكن العثور على الصفحة التي تبحث عنها.',
      D: 'لم يتم العثور على'
    }
  },

  // App: Session
  SESSION: {
    ENDED: {
      L: 'انتهت الجلسة.',
      D: 'تسجيل الدخول لبدء جلسة جديدة.'
    }
  },

  // App: Pull-to-Refresh
  PTR: {
    RELEASE: 'أفلت ليتم التحديث',
    REFRESH: 'جاري التحديث...',
    PULL: 'إسحب للأسفل للتحديث'
  },

  // App: Drawer
  DRAWER: {
    HEADER: {
      ACCOUNT: 'الحساب',
      APP: 'تطبيق',
      SOCIAL: 'اجتماعي',
      LEGAL: 'القانون'
    },
    ITEM: {
      PROFILES: {
        L: 'نمط الحياة',
        D: 'إدارة اهتماماتك'
      },
      PREFERENCES: {
        L: 'تفضيلات',
        D: 'اللغة والمظهر والمزيد ...'
      },
      GET_STARTED: {
        L: 'البدء',
        D: 'بدء أو تسجيل الدخول للمتابعة'
      },
      WALLET: {
        L: 'محفظة',
        D: 'طرق الدفع'
      },
      LOGOUT: {
        L: 'تسجيل خروج',
        D: 'حفظ وإنهاء التطبيق'
      },
      ABOUT: {
        L: 'معلومات',
        D: 'الإصدار والدعم'
      },
      UPDATES: {
        L: 'ملاحظات الإصدار',
        D: 'الافراج عن التغييرات والتحديثات'
      },
      RELEASE: {
        L: 'تحقق من وجود تحديثات...',
        D: 'تحديث التطبيق إلى أحدث إصدار'
      },
      HELP: {
        L: 'مساعدة',
        D: 'أدلة والأسئلة الشائعة'
      },
      FEEDBACK: {
        L: 'استجابة',
        D: 'نحن نحب أن نسمع منك'
      },
      PRIVACY: {
        L: 'سياسة خاصة',
        D: 'نحن نأخذ خصوصيتك على محمل الجد'
      }
    }
  },

  // App: Updates
  UPDATES: {
    TITLE_CHECK: 'التحقق من التحديثات ...',
    TITLE_NEW: 'تحديث جديد',
    TITLE_NO_UPDATES: 'لا تحديث',
    MESSAGE_NEW: 'يتوفر تحديث جديد للتنزيل.',
    UPDATE: 'تحديث',
    NOT_NOW: 'ليس الان'
  },

  // App: Authentication
  AUTH: {
    AUTHENTICATING: 'مصادقة ...',
    LOGOUT: 'تسجيل خروج...'
  },

  // Ecosystem Specific Strings
  E: {
    HOMES: {
      NAME: 'منازل',
      NAME_EDIT: 'منازل',
      ADD_L: 'إضافة منزل عن طريق النقر',
      NO_SPACE_L: 'لا يوجد مساحة',
      NO_SPACE_D: 'أنت بحاجة إلى مساحة أكبر لإضافة منزل جديد. هل ترغب في شراء مساحة أكبر؟',
      ROOMS: 'Rooms',
      APPEARANCE: 'Appearance',
      FEATURES: 'Features',
      GALLERY: 'Gallery',
      SERVICES: 'Services',
      AGENTS: 'Agents',
      ROOMS_BEDROOMS: 'غرف نوم',
      ROOMS_BATHROOMS: 'الحمامات',
      ROOMS_KITCHENS: 'مطابخ',
      ROOMS_LIVING: 'غرف المعيشة',
      ROOMS_DINING: 'غرف الطعام',
      ROOMS_OTHER: 'غرف اخرى',
      TAG_MYHOME: 'منازلي',
      TAG_INTERESTED: 'Interested',
      NO_LAND: 'No land',
      _BEDS: 'beds | سرير | beds',
      _BATHS: 'baths | bath | baths',
      BEDS: '0 beds | 1 bed | {count} beds',
      BATHS: '0 baths | 1 bath | {count} baths',
      BEDROOMS: 'لا غرف نوم | 1 غرفة نوم | {count} غرف نوم',
      BATHROOMS: 'لا حمامات | 1 حمام | {count} حمامات',
      BATHROOMS_HALF: 'لا نصف | 1 نصف | {count} نصفي',
      BATHROOMS_FULL: 'لا كامل | 1 كامل | {count} كامل',
      PREPARE_SPOT: 'جار التحميل...',
      PREPARE_SPOT_D: 'إعداد مكان لمنزلك.',
      ADD_HOME: 'أضف البيت',
      ADD_YOUR_HOME: 'أضف منزلك',
      ENTER_YOUR_HOME_ADDRESS: 'أدخل عنوان منزلك',
      HOME_ADDRESS: 'عنوان المنزل',
      HOME_ADDRESS_D: 'لنبدأ بإدخال عنوان منزلك.',
      SEARCHING: 'يبحث...',
      SEARCHING_D: 'جمع المعلومات عن منزلك.',
      REFINING_SEARCH: 'تحسين البحث ...',
      REFINING_SEARCH_D: 'أدخل تفاصيل إضافية ، مثل رقم الشقة (على سبيل المثال 21B) ، للعثور على العقار.',
      EVALUATE: 'تقييم ...',
      EVALUATE_D: 'تقييم منزلك على أساس السوق.',
      FOUND_HOME: 'وأضاف البيت',
      FOUND_HOME_D: 'عظيم! تمت إضافة منزلك.',
      VERIFY_HOME: 'تحقق من منزلك؟',
      VERIFY_HOME_D: 'يتم إرسال رمز خاص إلى عنوانك!',
      HISTORY: {
        TITLE: 'التاريخ',
        LABEL_TT: 'عرض السجل'
      },
      RECORD: {
        TITLE: 'السجل العام',
        LABEL_TT: 'عرض السجل العام'
      },
      MODIFY: {
        LABEL_TT: 'تحديث البيت'
      },
      VIEW: {
        LABEL_TT: 'عرض البيت'
      },
      DELETE: {
        LABEL_TT: 'إزالة المنزل',
        TITLE_QUERY: 'إزالة المنزل',
        MESSAGE_QUERY: 'هل أنت متأكد أنك تريد إزالة هذا المنزل؟',
        DELETING: 'إزالة المنزل'
      },
      DETAILS: {
        STARTING_VALUE: 'قيمة المنزل تبدأ',
        FEATURES: 'المميزات',
        FINISHES: 'التشطيبات',
        FEATURES_FINISHES: 'المميزات / التشطيبات',
        MARKET_ADJUSTMENT: 'تعديل السوق'
      },
      AGE: {
        OWNERSHIP: 'مالك جديد | أقل من 2 سنة من الملكية | {count} سنوات من الملكية',
        YEARS: '1 سنة | {count} سنة | {count} سنوات'
      }
    },
    BUSINESS: {
      NAME: 'اعمال',
      NAME_EDIT: 'اعمال',
      ADD_L: 'أضف نشاطًا تجاريًا عن طريق النقر',
      ADD_YOUR_PRODUCT: 'أضف عملك',
      PREPARE_SPOT: 'جار التحميل...',
      PREPARE_SPOT_D: 'تحضير مكان لعملك.',
      ENTER_YOUR_PRODUCT_ADDRESS: 'أدخل اسم عملك وعنوانه',
      PRODUCT_ADDRESS: 'الاسم التجاري',
      PRODUCT_ADDRESS_D: 'لنبدأ بإدخال اسم عملك وعنوانه.',
      EDIT: {
        LABEL_TT: 'تحديث الاتصال'
      }
    },
    FOODS: {
      NAME: 'أطعمة',
      NAME_EDIT: 'أطعمة',
      ADD_L: 'أضف قائمة طعام من خلال النقر فوق',
      BUSINESS: {
        NAME: 'مطعم'
      },
      VIEW: {
        LABEL_TT: 'افتح'
      },
      MENU: 'قائمة طعام',
      TEAM: 'الفريق',
      ACTIVITY: 'نشاط',
      ACTIVITY_TODAY: 'نشاط اليوم',
      PREFERENCES_CHECK: 'التفضيلات تحقق',
      PERSONALIZE: 'تخصيص',
      PERSONALIZE_MENU: 'تخصيص القائمة الخاصة بك',
      REFINE: 'صقل',
      ORDER_ADD: 'أضف إلى طلبك',
      ORDER_SEND: 'ارسال الطلب',
      INGREDIENT_ADD: 'إضافة عنصر',
      SIDE_CHOOSE: 'اختيار الجانب',
      OFFERS: 'عروض',
      EXPLORE: 'إكتشف',
      SALADS: 'سلطة',
      BOWLS: 'عاء'
    },
    ANIMALS: {
      NAME: 'حيوانات',
      NAME_EDIT: 'حيوانات',
      ADD_L: 'أضف حيوانًا عن طريق النقر على'
    }
  }
}
