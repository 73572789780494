import { LocalStorage } from 'quasar'
import { buildVersion, buildTimestamp } from './../../statics/build'

import moment from 'moment'

export default () => ({
  version: '1.9B (2.0R)',
  version_build: buildVersion,
  version_timestapm: buildTimestamp,
  version_flag: 'beta',
  version_date: moment(buildTimestamp * 1000).format('MMMM Do, YYYY'),
  version_date_ddmmyyyy: moment(buildTimestamp * 1000).format('DDMMYYYY'),
  version_time: moment(buildTimestamp * 1000).format('hh:mmA'),
  lang: LocalStorage.get.item('lang') || 'enUS',
  darkmode: LocalStorage.get.item('darkmode') || false,
  production_domain: 'https://mywings.app',
  init: false,
  preferences: {
    display: {
      groups: {
        description: LocalStorage.get.item('display_groups_description_enabled') !== 'false'
      },
      cards: {
        mode: LocalStorage.get.item('display_cards_mode') === 'true'
      },
      sheets: {
        draggable: true,
        retain: LocalStorage.get.item('display_sheets_retain') === 'true'
      },
      effects: {
        enabled: LocalStorage.get.item('display_effects_enabled') !== 'false'
      }
    },
    sound: {
      theme: 'glasswing',
      enabled: LocalStorage.get.item('sound_enabled') !== 'false'
    },
    voice: {
      enabled: LocalStorage.get.item('voice_enabled') === 'true'
    },
    branding: {
      enabled: LocalStorage.get.item('branding_enabled') === 'true'
    },
    dev_presense: {
      enabled: LocalStorage.get.item('dev_presense_enabled') === 'true'
    }
  },
  bag: {
    times: {
      created: null,
      updated: null
    },
    items: [],
    total: 0.00
  },
  wallet: {
    us_dollars: {
      amount: LocalStorage.get.item('account_wallet_us_dollars') || 100.00,
      history: []
    }
  },
  requests: LocalStorage.get.item('requests') || [],
  intentions: {
    dropoffs: {
      scanned: null,
      expires: null,
      lastscan: null
    },
    service: null,
    worflows: {
      // multiple businesses can reside here (by id)
      'proteinhouse_marlborough-9c5e7e1eb769d58f9e3a1907d8f84328': {
        bag: {
          count: 0
        }
      }
    }
  },
  products: {
    updated: false,
    list: [],
    group: {}
  },
  channels: {
    updated: false,
    list: [],
    group: {}
  },
  pubnub: {
    subscribeKey: 'sub-c-6ef8f7b4-860c-11e9-99de-d6d3b84c4a25',
    publishKey: 'pub-c-4a7e4814-55a0-4e5f-98d7-eba6d8e92cd3'
  },
  io: {
    id: null,
    idx: null,
    status: null,
    name: null,
    phone: null,
    email: null,
    email_verified: null,
    funds: null,
    currency: null,
    picture: null,
    ecosystem_id: /* LocalStorage.get.item('ecosystem_id') || */ 'business',
    ecosystems_users_id: LocalStorage.get.item('ecosystem_users_id') || null,
    ecosystems_users_type: LocalStorage.get.item('ecosystem_users_type') || 'OWNER',
    io_access_token: null,
    io_session_token: null
  },
  ecosystem: {
    homes: {
      routes: {
        products: '/homes',
        productsEdit: '/homes/:id'
      }
    },
    foods: {
      routes: {
        foods: '/foods',
        foodsEdit: '/foods/:id'
      }
    },
    business: {
      routes: {
        foods: '/fobusinessods',
        foodsEdit: '/business/:id'
      }
    }
  }
})
