export const getProducts = (state) => {
  return state.products
}

export const getProduct = (state, index) => {
  return state.products.list[index]
}

export const getChannels = (state) => {
  return state.channels
}

export const getChannel = (state, index) => {
  return state.channels.list[index]
}
