import VueI18n from 'vue-i18n'
import messages from 'src/i18n'

export default ({ app, Vue }) => {
  Vue.use(VueI18n)

  // read cookie
  let fallback = 'enUS'
  let lang = localStorage.getItem('lang') || fallback

  // Set i18n instance on app
  app.i18n = new VueI18n({
    locale: lang,
    fallbackLocale: fallback,
    messages
  })

  const el = document.documentElement
  let dir = (lang === 'arSA') ? 'rtl' : 'ltr'
  el.setAttribute('dir', dir)
  el.setAttribute('lang', app.i18n.t('lang'))
  setTimeout(() => {
    try {
      document.getElementById('appTitle').setAttribute('dir', dir)
    } catch (e) {}
  }, 400)
}
