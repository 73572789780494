export default {
  lang: 'en',
  // App: Common
  YES: 'Yes',
  NO: 'No',
  CANCEL: 'Cancel',
  CLOSE: 'Close',
  DONE: 'Done',
  OK: 'OK',
  EDIT: 'Edit',
  VIEW: 'View',
  USE: 'Use',
  MORE: 'More',
  ADD: 'Add',
  REMOVE: 'Remove',
  LEARN_MORE: 'Learn more',
  GET_STARTED: 'Get Started',
  LOGGED_IN: 'Logged in',
  NOT_LOGGED_IN: 'Not logged in',
  NOT_FOUND: 'Not found',
  LANGUAGE: 'Language',
  LOGOUT: 'Logout',
  LOGOUT_MESSAGE: 'Are you sure you want to quit?',
  HELLO_FRIEND: 'Hello, friend!',
  WELCOME_FRIEND: 'Welcome, friend!',
  HELLO_NAME: 'Hello, {name}!',
  WELCOME: 'Welcome',
  PROGRESS: 'Progress',
  COMPLETE: 'Complete',
  PROFILE: 'Profile',
  SHARE: 'Share',
  VERIFY: 'Verify',
  VERIFIED_REQUESTED: 'Verification requested...',
  VERIFIED_SENT: 'Verification sent...',
  VERIFIED_EXPIRED: 'Verification expired',
  VERIFIED_VERIFIED: 'Verified',
  VERIFIED: 'Verified',
  VERIFIED_NOT: 'Not yet claimed',
  LEGAL_RIGHTS: 'All rights reserved.',
  MORE_DETAIL: 'more detail',
  FIND: 'Find',
  ABBR_EXAMPLE: 'e.g.',
  OVERVIEW: 'Overview',
  SUMMARY: 'Summary',
  FOUND: '{amount} found',
  NAVIGATE_BACK: 'Go back',
  ANONYMOUS_FRIEND: 'anonymous friend',
  APPEARANCE: 'Appearance',
  APPEARANCE_DARKMODE: 'Dark Mode',
  APPEARANCE_DARKMODE_INFO: 'Enable dark mode all the time',
  CHANNEL: {
    LABEL_TT: 'Live Channel'
  },
  LOGIN: {
    LABEL_TT: 'Log in / Sign up'
  },
  ORDERBY: {
    LABEL_TT: 'Order By',
    ADD: 'Add Service'
  },
  OPEN: 'Open',
  CLOSED: 'Closed',
  CONNECTING: 'Connecting...',
  NEW: 'No Activity',
  EMPTY_COMMUNICATION: 'No communication yet',
  COMMUNICATION_UPDATE: 'Communication Update',
  ONLINE: {
    LABEL: 'Online'
  },
  OFFLINE: {
    LABEL: 'Offline'
  },
  CALL: 'Call',
  AVAILABILITY: {
    LABEL: 'Availability',
    PARTIAL: 'Partial services available',
    FULL: 'Full services available',
    CALLS_YES: 'Give us a call!',
    CALLS_NO: 'Do not call'
  },
  LOYALTY: {
    LABEL: 'Loyalty',
    LABEL_TT: 'Loyalty'
  },
  SERVICES: {
    LABEL_TT: 'Services',
    ADD: 'Add Service'
  },
  WIFI: {
    LABEL: 'Wi-Fi'
  },
  BARISTA_STAFF: {
    LABEL: 'Barista: Staff #'
  },
  BARISTA_TRAINING: {
    LABEL: 'Barista: In-Training'
  },
  BARISTA_WAIT_TIME: {
    LABEL: 'Barista: wait time',
    D: 'Approximate wait time to begin an order'
  },
  KITCHEN_STAFF: {
    LABEL: 'Kitchen: Staff #'
  },
  KITCHEN_TRAINING: {
    LABEL: 'Kitchen: In-Training'
  },
  KITCHEN_WAIT_TIME: {
    LABEL: 'Kitchen: wait time',
    D: 'Approximate wait time to begin an order'
  },
  WAIT_TIME: {
    LABEL: 'Wait time',
    D: 'Communicate the average time it takes before a new order is serviced.'
  },
  SERVICE_TIME: {
    LABEL: 'Service time',
    D: 'Communicate the average time it takes for an order to be serviced.'
  },
  CTA: {
    LABEL: 'Floating Button',
    D: 'Select youre current preferred action to be displayed (as a floating button) as your prefered method for recieving service requests.'
  },
  OPERATIONS: {
    LABEL_TT: 'Operations'
  },
  OFFERS: {
    LABEL: 'Offers',
    LABEL_TT: 'Current Offers',
    ADD: 'Add Offer'
  },
  TASKS: {
    LABEL_TT: 'Tasks',
    LABEL_ALT_TT: 'To Do',
    ADD: 'Add a Task'
  },
  REQUESTS: {
    LABEL_TT: 'Requests'
  },
  MEDIA: {
    PHOTOS: {
      ADD: 'Add a Photo',
      DROP: 'Drop Photo',
      PROCESSING: 'Processing Photo'
    }
  },
  CHANGES: {
    LABEL_TT: 'View changes'
  },
  PROFILES: {
    SWITCH: {
      LABEL_TT: 'change interest',
      MESSAGE: 'Do you want to switch your interest to {profile}?'
    }
  },
  DESCRIPTION: {
    LABEL: 'Description'
  },
  DROPOFF: {
    LABEL_TT: 'Dropoff',
    DESCRIPTION: 'You are at a { param } dropoff location for you to order from.'
  },
  WINGLET: {
    SCAN: {
      L: 'Scan',
      D: 'Scan a QR Code'
    },
    ORDER_TO_PICKUP: {
      L: 'Order for Pickup',
      D: 'You can order and pickup from this spot.'
    },
    ORDER_TO_PICKUP_CURBSIDE: {
      L: 'Order for Curbside',
      D: 'You can order and pickup from this curbside spot.'
    },
    ORDER_TO_STAY: {
      L: 'Order to Stay',
      D: 'You can order and stay at this spot for an allotted period.'
    },
    PICKUP_AN_ORDER: {
      L: 'Pickup an Order',
      D: 'You can pickup your order from this spot.'
    }
  },
  CLAIM: {
    BUSINESS: 'Claim',
    BUSINESS_CLAIMED: 'Verified',
    VERIFY_TITLE: 'ID Check',
    VERIFY_MESSAGE_NO: 'Could not find your ID on your website. Try again later.'
  },
  ORDERS: {
    LABEL: 'Orders',
    ACCEPTING: 'Accepting Orders',
    NOT_ACCEPTING: 'Not Accepting Orders',
    ACCEPTING_IN: 'Accepting Orders in {param}',
    NOT_ACCEPTING_IN: 'Not Accepting orders in {param}'
  },
  MINUTE: 'minute',
  MINUTE_MIN: 'min',
  MINUTES_VAL_SH: '0 mins | 1 min | {count} mins',
  MINUTES_VAL: '0 minutes | 1 minute | {count} minutes',
  MINUTES_ATLEAST_VAL: '-- | at least 1 minute | at least {count} minutes',
  MINUTES_ATLEAST_VAL_SH: '-- | 1+ min | {count}+ mins',

  LIVE: 'live',
  COMMUNICATION: {
    LIVE: 'Live communication',
    LAST: 'Last known communication'
  },
  PERSONALIZE: {
    LABEL_TT: 'Personalize',
    D: 'Your lifestyle preferences'
  },
  PERSONALIZE_LIST: {
    LABEL_TT: 'Personalize',
    D: 'List of health and lifestyle personalizations'
  },

  LOCATION: 'Your Location',
  LOCATION_MESSAGE: 'Allowing access to your device\'s location will help narrow your business search to your area.',

  FOODS: {
    LABEL_TT: 'foods'
  },
  DINE_IN: {
    LABEL: 'Dine In'
  },
  AUTO: {
    LABEL_TT: 'Auto'
  },
  BAYS: {
    LABEL: 'Available Bays',
    COUNT: '0 bays | 1 bay | {count} bays'
  },
  SECONDS: {
    COUNT: 'seconds | second | seconds'
  },
  ALL: 'All',
  NONE: 'None',
  BUSINESS: {
    LABEL_TT: 'business'
  },
  PICKUP: {
    LABEL_TT: 'Pickup'
  },
  PARKING: {
    LABEL: 'Parking'
  },
  DRIVETHRU: {
    LABEL: 'Drive-through'
  },
  WALKINS: {
    LABEL: 'Walk-ins'
  },
  CURBSIDE: {
    LABEL: 'Curbside'
  },
  DRIVETHRU_ORDERING: {
    LABEL: 'Drive-through'
  },
  ACCEPTS: {
    LABEL_TT: 'Accepts'
  },
  CASH: {
    LABEL: 'Cash'
  },
  CREDITCARD: {
    LABEL: 'Credit Card'
  },
  CRPTO: {
    LABEL: 'Cryptocurrency'
  },
  APPLEPAY: {
    LABEL: 'Apple Pay'
  },
  UBEREATS: {
    LABEL: 'Uber Eats'
  },
  DOORDASH: {
    LABEL: 'DoorDash'
  },
  POSTMATES: {
    LABEL: 'Postmates'
  },
  GRUBHUB: {
    LABEL: 'Grubhub'
  },
  HOMES: {
    LABEL_TT: 'Homes'
  },
  AMENITIES: {
    LABEL: 'Amenities'
  },
  AVAILABLE: 'Available',
  AVAILABLE_PROPERTIES: {
    LABEL: 'Availability'
  },
  DEPARTMENT_STORE: {
    LABEL_TT: 'Department'
  },
  INVENTORY: {
    LABEL: 'Inventory',
    FULL: 'Full',
    PARTIAL: 'Partial',
    LOW: 'Low'
  },
  ANIMALS: {
    LABEL_TT: 'Animals'
  },
  TELEHEALTH: {
    LABEL: 'Telehealth'
  },
  WEBSITE: {
    LABEL: 'Website',
    OFFICIAL: 'Official Website'
  },
  MENU: {
    LABEL: 'Menu'
  },
  PHONE: {
    LABEL: 'Phone'
  },
  IDENTITY: {
    LABEL: 'About'
  },
  QRCODE: {
    LABEL: 'QR Code'
  },
  WEBLINK: {
    LABEL: 'Website Link',
    SHORT: 'Website Link (short)'
  },
  SHARESHEET: {
    LABEL: 'Device Sharesheet'
  },
  SHARELINKS: {
    TWITTER: 'Wings - Check out our live status channel!'
  },
  BURGERS: {
    LABEL: 'Burgers'
  },
  SHOTS: {
    LABEL: 'Shots'
  },
  ACAI: {
    LABEL: 'Açaí'
  },
  ACAI_BOWLS: {
    LABEL: 'Açaí'
  },
  SHAKES: {
    LABEL: 'Shakes'
  },
  JUICES: {
    LABEL: 'Juices'
  },
  BOWLS: {
    LABEL: 'Bowls'
  },
  SALAD: {
    LABEL: 'Salad'
  },
  SMOOTHIES: {
    LABEL: 'Smoothies'
  },
  SOUPS: {
    LABEL: 'Soups'
  },
  COFFEE: {
    LABEL: 'Coffee'
  },
  SUBS: {
    LABEL: 'Subs'
  },
  WRAPS: {
    LABEL: 'Wraps'
  },
  TEA: {
    LABEL: 'Tea'
  },
  WINE: {
    LABEL: 'Wine'
  },
  BEER: {
    LABEL: 'Beer'
  },
  ALLOWED_CAPACITY: {
    LABEL: 'Allowed Capacity'
  },
  ICECREAM: {
    LABEL: 'Icecream'
  },
  CRYPTO: {
    LABEL: 'Crypto'
  },
  MASKS: {
    LABEL: 'Masks'
  },
  SANATIZERS: {
    LABEL: 'Sanatizers'
  },
  HAND_SANATIZERS: {
    LABEL: 'Hand Sanatizers'
  },
  SAFETY: {
    LABEL_TT: 'Safety'
  },
  ALLERGENS: {
    LABEL_TT: 'Allergens'
  },
  FOOD_VEGAN: {
    LABEL: 'Vegan'
  },
  FOOD_GLUTEN: {
    LABEL: 'Gluten-Free'
  },
  FOOD_DAIRY: {
    LABEL: 'Dairy-Free'
  },
  ACCESSIBILITY: {
    LABEL: 'Accessibility'
  },
  AVOCADOS: {
    LABEL: 'Avocados'
  },
  EGGS: {
    LABEL: 'Eggs'
  },
  HONEY: {
    LABEL: 'Honey'
  },
  SPINACH: {
    LABEL: 'Spinach'
  },

  // App: Version
  VERSION: {
    L: 'version',
    LATEST: 'You are up to date!',
    VIEW_RELEASE_NOTES: 'View release notes',
    CHECK_UPDATES: 'Check for updates'
  },

  // App: Support
  SUPPORT: {
    L: 'support',
    D: 'We are here to help!',
    SYSTEM_STATUS_OK: 'Systems up and running.',
    VISIT_FAQ: 'Visit our FAQ',
    REPORT_ISSUE: 'Report an issue'
  },

  // App: Credits
  CREDITS: {
    L: 'credits',
    T: 'Thank you!',
    D: 'It is with your love and support that continues to help us grow and transform.',
    L_SUPPORTERS: 'supporters',
    L_ADVISORS: 'advisors',
    L_PARTNERS: 'partners',
    L_TECHNOLOGIES: 'technologies'
  },

  // App: Errors
  ERRORS: {
    E404: {
      L: 'Oops! We couldn\'t find that page.',
      D: 'was not found.'
    }
  },

  // App: Session
  SESSION: {
    ENDED: {
      L: 'Session Ended.',
      D: 'Login to start a new session.'
    }
  },

  // App: Pull-to-Refresh
  PTR: {
    RELEASE: 'Release to refresh',
    REFRESH: 'Refreshing…',
    PULL: 'Pull down to refresh'
  },

  // App: Drawer
  DRAWER: {
    HEADER: {
      ACCOUNT: 'Account',
      APP: 'App',
      SOCIAL: 'Social',
      LEGAL: 'Legal'
    },
    ITEM: {
      PROFILES: {
        L: 'Lifestyle',
        D: 'Manage your interests'
      },
      PREFERENCES: {
        L: 'Preferences',
        D: 'Language, appearance, and more...'
      },
      GET_STARTED: {
        L: 'Get Started',
        D: 'Start or login to continue'
      },
      WALLET: {
        L: 'Wallet',
        D: 'Payment methods'
      },
      LOGOUT: {
        L: 'Logout',
        D: 'Save & quit application'
      },
      ABOUT: {
        L: 'About',
        D: 'Version & Support'
      },
      UPDATES: {
        L: 'Release Notes',
        D: 'Release changes and updates'
      },
      RELEASE: {
        L: 'Check for Updates...',
        D: 'Update app to the latest version'
      },
      HELP: {
        L: 'Help',
        D: 'Guides and FAQs'
      },
      FEEDBACK: {
        L: 'Feedback',
        D: 'We love to hear from you'
      },
      PRIVACY: {
        L: 'Privacy Policy',
        D: 'Effective May 1, 2020'
      },
      TERMS: {
        L: 'Terms of Use',
        D: 'Effective April 28, 2020'
      }
    }
  },

  // App: Updates
  UPDATES: {
    TITLE_CHECK: 'Checking Updates...',
    TITLE_NEW: 'New Update',
    TITLE_NO_UPDATES: 'No Update',
    MESSAGE_NEW: 'A new update is available to download.',
    UPDATE: 'Update',
    NOT_NOW: 'Not now'
  },

  // App: Authentication
  AUTH: {
    AUTHENTICATING: 'Authenticating...',
    LOGOUT: 'Signing out...'
  },

  // Ecosystem Specific Strings
  E: {
    HOMES: {
      NAME: 'homes',
      NAME_EDIT: 'homes',
      ADD_L: 'Add a home by tapping',
      NO_SPACE_L: 'no space',
      NO_SPACE_D: 'You need more space to add a new home. Would you like to purchase more space?',
      ROOMS: 'Rooms',
      APPEARANCE: 'Appearance',
      FEATURES: 'Features',
      GALLERY: 'Gallery',
      SERVICES: 'Services',
      AGENTS: 'Agents',
      ROOMS_BEDROOMS: 'Bedrooms',
      ROOMS_BATHROOMS: 'Bathrooms',
      ROOMS_KITCHENS: 'Kitchens',
      ROOMS_LIVING: 'Living Rooms',
      ROOMS_DINING: 'Dining Rooms',
      ROOMS_OTHER: 'Other Rooms',
      TAG_MYHOME: 'My homes',
      TAG_INTERESTED: 'Interested',
      NO_LAND: 'No land',
      _BEDS: 'beds | bed | beds',
      _BATHS: 'baths | bath | batPhs',
      BEDS: '0 beds | 1 bed | {count} beds',
      BATHS: '0 baths | 1 bath | {count} baths',
      BEDROOMS: 'No bedrooms | 1 bedroom | {count} bedrooms',
      BATHROOMS: 'No bathrooms | 1 bathroom | {count} bathrooms',
      BATHROOMS_HALF: 'no half | 1 half | {count} half',
      BATHROOMS_FULL: 'no full | 1 full | {count} full',
      PREPARE_SPOT: 'Loading...',
      PREPARE_SPOT_D: 'Preparing a place for your home.',
      ADD_HOME: 'Add Home',
      ADD_YOUR_HOME: 'Add your home',
      ENTER_YOUR_HOME_ADDRESS: 'Enter your home address',
      HOME_ADDRESS: 'Home Address',
      HOME_ADDRESS_D: 'Let\'s start by entering your home address.',
      SEARCHING: 'Searching...',
      SEARCHING_D: 'Gathering information about your home.',
      REFINING_SEARCH: 'Refining search...',
      REFINING_SEARCH_D: 'Enter additional detail, such as apartment number (ex. 21B), to find the property.',
      EVALUATE: 'Evaluating...',
      EVALUATE_D: 'Evaluating your home based on market.',
      FOUND_HOME: 'Home Added',
      FOUND_HOME_D: 'Great! Your home has been added.',
      VERIFY_HOME: 'Verify your home?',
      VERIFY_HOME_D: 'A special code is sent to your address!',
      HISTORY: {
        TITLE: 'History',
        LABEL_TT: 'View history'
      },
      RECORD: {
        TITLE: 'Public Record',
        LABEL_TT: 'View public record'
      },
      MODIFY: {
        LABEL_TT: 'Update home'
      },
      VIEW: {
        LABEL_TT: 'View home'
      },
      DELETE: {
        LABEL_TT: 'Remove home',
        TITLE_QUERY: 'Remove home',
        MESSAGE_QUERY: 'Are you sure you want to remove this home?',
        DELETING: 'Removing home'
      },
      DETAILS: {
        STARTING_VALUE: 'Property starting value',
        FEATURES: 'Features',
        FINISHES: 'Finishes',
        FEATURES_FINISHES: 'Features / Finishes',
        MARKET_ADJUSTMENT: 'Market adjustment'
      },
      AGE: {
        OWNERSHIP: 'new owner | less than 2 years of ownership | {count} years of ownership',
        YEARS: 'new construction | new construction | {count} years'
      }
    },
    BUSINESS: {
      NAME: 'business',
      NAME_EDIT: 'business',
      ADD_L: 'Add a business by tapping',
      ADD_YOUR_PRODUCT: 'Add your business',
      PREPARE_SPOT: 'Loading...',
      PREPARE_SPOT_D: 'Preparing a place for your business.',
      ENTER_YOUR_PRODUCT: 'Enter your business name and address',
      PRODUCT_LOCATION: 'Business Name',
      PRODUCT_LOCATION_D: 'Let\'s start by entering your business name and address.',
      SEARCHING: 'Loading...',
      SEARCHING_D: 'Gathering information about your business',
      FOUND_PRODUCT: 'Creating Channel...',
      FOUND_PRODUCT_D: 'A live channel is being prepared for your business',
      BUSINESS_ADDED: 'Business Added',
      PRODUCT_READY: 'All set!',
      PRODUCT_READY_D: 'Your business channel is ready',
      EDIT: {
        LABEL_TT: 'Update communication'
      },
      VIEW: {
        LABEL_TT: 'View Business'
      },
      DELETE: {
        LABEL_TT: 'Remove business',
        TITLE_QUERY: 'Remove business',
        MESSAGE_QUERY: 'Are you sure you want to remove this business?',
        DELETING: 'Removing business'
      }
    },
    FOODS: {
      NAME: 'foods',
      NAME_EDIT: 'foods',
      ADD_L: 'Add a menu by tapping',
      BUSINESS: {
        NAME: 'Restaurant'
      },
      VIEW: {
        LABEL_TT: 'View'
      },
      MENU: 'Menu',
      TEAM: 'Team',
      ACTIVITY: 'Activity',
      ACTIVITY_TODAY: 'Today\'s Activity',
      PREFERENCES_CHECK: 'Preferences check',
      PERSONALIZE: 'Personalize',
      PERSONALIZE_MENU: 'Personalize your menu',
      REFINE: 'Refine',
      ORDER_ADD: 'Add order',
      ORDER_SEND: 'Send order',
      INGREDIENT_ADD: 'Add an ingredient',
      SIDE_CHOOSE: 'Choose a side',
      OFFERS: 'Offers',
      EXPLORE: 'Explore',
      SALADS: 'Salads',
      BOWLS: 'Bowls'
    },
    ANIMALS: {
      NAME: 'animals',
      NAME_EDIT: 'animals',
      ADD_L: 'Add an animal by tapping'
    }
  }
}
