<template>
  <div id="q-app" class="moveup-disabled">
    <q-ajax-bar ref="bar" position="top" size="4px" color="primary" :delay="0" skip-hijack-2/>
    <transition
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
      mode="out-in"
      :duration="300"
      @leave="resetScroll"
    >
      <router-view v-on:startAjaxBar="onStartAjaxBar" v-on:stopAjaxBar="onStopAjaxBar"/>
    </transition>
  </div>
</template>

<script>

// only load this in production
// import LogRocket from 'logrocket'
// if (window.location.host.split('.').indexOf('app') >= 2) {
//   LogRocket.init('lfyzov/letsbutterflyapp')
//   console.info(`🦋 [LogRocket] :state init`)
// }

export default {
  name: 'App',
  methods: {
    resetScroll (el, done) {
      document.documentElement.scrollTop = 0
      document.body.scrollTop = 0
      done()
    },
    onStartAjaxBar () {
      // console.log('onStartAjaxBar')
      // this.$refs.bar.start()
    },
    onStopAjaxBar () {
      // console.log('onStopAjaxBar')
      // this.$refs.bar.stop()
    }
  }
}
</script>

<style scoped lang="stylus">
@import '~variables'

main
  text-align center
  margin-top 40px
</style>
