// Magic.Link
import { Magic } from 'magic-sdk'

const useMagicProductionKey = true
const magicLink = new Magic(useMagicProductionKey ? 'pk_live_021C33000C015223' : 'pk_test_51C23D17705F42EB')

export default ({ Vue }) => {
  Vue.prototype.$magic = magicLink
}

export { Magic, magicLink }
